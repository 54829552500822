<template>
  <div class="box">
    <div class="tabs">
      <div
        :class="queryParams.type == 1 ? 'tabPaneGree' : 'tabPane'"
        @click="typeChange(1)"
      >
        开篇 （{{ totalNum }}）
      </div>
      <div
        :class="queryParams.type == 2 ? 'tabPaneGree' : 'tabPane'"
        @click="typeChange(2)"
      >
        续写（{{ renewTotalNum }}）
      </div>
    </div>
    <div
      class="content"
      v-loading="loading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.5)"
      element-loading-text="拼命加载中"
    >
      <div
        class="items"
        v-for="item in list"
        :key="item.articleId"
        @click="goDetail(item)"
      >
        <div
          :class="[
            'constItem',
            queryParams.type == 1 ? 'constItemGee' : 'constItemRed',
            item.offShelf ? 'constItemOp' : '',
          ]"
        >
          <div class="img">
            <el-image
              style="width: 100%; height: 100%; object-fit: cover"
              :src="item.imageUrl"
              fit="cover"
            ></el-image>
            <div class="title">{{ item.title }}</div>
          </div>

          <div class="center">
            <div class="likeItem">
              <img
                style="width: 16px; margin-right: 6px"
                src="./images/write.png"
              />
              <span> {{ item.renewNum }}</span>
            </div>
            <div class="likeItem">
              <img
                style="width: 16px; margin-right: 6px"
                src="./images/like.png"
              />
              <span> {{ item.favorNum }}</span>
            </div>
          </div>
          <div class="bottom">
            <i class="el-icon-alarm-clock" style="font-size: 18px"></i>
            <span> {{ item.createTime }}</span>
          </div>
        </div>
        <div class="foot">
          <span v-if="item.publishState == 0">作品未发布，暂无收益</span>
          <div class="footBox" v-else>
            <img
              style="width: 30px; margin-right: 6px"
              src="./images/shouyi.png"
            />
            <div class="left">
              <div class="money">
                <div>
                  <span style="color: #fff"> {{ item.ethAmount }}</span
                  >ETH
                </div>

                <img
                  style="width: 20px; margin-left: 8px"
                  src="@/assets/images/ethBig.png"
                  alt=""
                />
              </div>
              <div class="money">
                <div>
                  <span style="color: #fff">{{ item.daicAmount }}</span> DAIC
                </div>

                <img
                  style="width: 20px; margin-left: 8px"
                  src="@/components/Header/images/btc.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { personalPage } from "@/api/nft";
export default {
  data() {
    return {
      list: [],
      queryParams: {
        page: 1,
        pageSize: 10,
        totalRows: 0,
        type: 1,
      },
      renewTotalNum: 0,
      totalNum: 0,
      loading: false,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      let param = {
        ...this.queryParams,
        accountId: this.accountId,
      };
      personalPage(param).then(({ code, data }) => {
        if (code == 1000) {
          this.list = data.list;
          this.renewTotalNum = data.renewTotalNum;
          this.totalNum = data.totalNum;
        }
        this.loading = false;
      });
    },
    goDetail(row) {
      // 存文章id
      this.$store.commit("SETAGOID", row.articleId);
      if (row.publishState == 1) {
        this.$router.push({
          path: "/Details",
        });
      } else {
        this.$router.push({
          path: "/AIText",
          query: {
            type: this.queryParams.type,
          },
        });
      }
    },
    typeChange(type) {
      this.queryParams.type = type;
      this.getList();
    },
    noImg(list) {
      let obj = list.find((i) => i.hasMainImage);
      return obj.urlPath;
    },
  },
  computed: {
    ...mapState({
      accountId: (state) => state.user.accountId,
    }),
  },
};
</script>

<style scoped lang="less">
.box {
  width: 1200px;
  margin: 20px auto;
  min-height: 80vh;
  .tabs {
    display: flex;
    height: 60px;
    .tabPaneGree {
      height: 36px;
      width: 130px;
      margin-right: 20px;
      line-height: 36px;
      border-radius: 18px;
      font-size: 16px;
      text-align: center;
      color: #000;
      background: #01e099;
      cursor: pointer;
    }
    .tabPane {
      height: 36px;
      width: 130px;
      margin-right: 20px;
      line-height: 36px;
      border-radius: 18px;
      font-size: 16px;
      text-align: center;
      color: #ccc;
      background: #41454c;
      cursor: pointer;
    }
  }

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .constItemOp {
      opacity: 0.5;
    }
    .constItemGee {
      border: solid 6px transparent;
      border-radius: 8px;
      background-image: linear-gradient(#fee, #fee),
        linear-gradient(to right, #ece92a, #01e39b);
      background-origin: border-box;
      background-clip: content-box, border-box;
    }
    .constItemRed {
      border: solid 6px transparent;
      border-radius: 8px;
      background-image: linear-gradient(#fee, #fee),
        linear-gradient(to right, #ece92a, #f56c6c);
      background-origin: border-box;
      background-clip: content-box, border-box;
    }
    .items {
      box-sizing: border-box;
      width: 18%;
      margin-right: 1.2%;
      margin-bottom: 20px;
      .constItem {
        cursor: pointer;
        .img {
          width: 100%;
          height: 120px;
          position: relative;
          .title {
            font-size: 14px;
            line-height: 30px;
            position: absolute;
            bottom: 0;
            font-weight: 600;
            padding-left: 6px;
            background-color: rgba(0, 0, 0, 0.5);
            width: 100%;
          }
        }

        .center {
          display: flex;
          align-items: center;
          padding: 4px 0;
          font-size: 12px;
          color: #979799;
          background-color: rgba(0, 0, 0, 0.5);
          .likeItem {
            display: flex;
            align-items: center;
            margin-left: 8px;
          }
        }
        .bottom {
          width: 100%;
          color: #979799;
          font-size: 14px;
          line-height: 30px;
          padding-left: 6px;
          background-color: rgba(0, 0, 0, 0.5);
          span {
            margin-left: 6px;
          }
        }
      }
      .foot {
        width: 100%;
        height: 72px;
        border-radius: 18px;
        opacity: 1;
        margin-top: 10px;
        background: #16191e;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #4e4f52;
        .footBox {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .left {
          .money {
            display: flex;
            align-items: center;
            justify-content: right;
            text-align: right;
            line-height: 26px;
          }
        }
      }
    }
  }
}
</style>
